import React from 'react'
import video from '../../Assets/Videos/main-animation.mp4'
import './MainVideo.scss'

function MainVideo() {
    return (
        <div className='videoContainer'>
            <video
                loop
                autoPlay
                muted
                playsInline
                src={video}
                type="video/mp4">
            </video>
        </div>
    )
}

export default MainVideo