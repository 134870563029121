import React from 'react'
import './Vision.scss'
import { motion, useScroll } from "framer-motion"

// Components
import Navbar from '../../Components/Navbar/Navbar'

// Assets
import storynft from '../../Assets/Images/storynft.png'
import visionBackground from '../../Assets/Images/visionbackground.jpg'

function Vision() {

    const { scrollYProgress } = useScroll();

    const MainNFT = () => {
        return (
            <div>
                <img src={storynft} alt="KB_Katana" className='otherNFT' />
            </div>
        )
    }

    const VisionSection = () => {
        return (
            <div className='sectionStart'>
                <h1>The Jungle</h1>
                <p>Tiger Streak is a collection of 10,000 3D Tiger NFTs—unique digital collectibles living on the Ethereum blockchain, from the hands of our award-winning artist Caetano. </p>
                <p>Your Tiger doubles as your key to the Zoosh Club and grants access to members-only benefits. It starts with exclusive metaverse collabs, NFT drops, live events, and access to our upcoming Web3 AI platform equipped with machine learning mechanisms.</p>
                <p>The Streak is a place where thought leaders and ambitious minds unite to build things—great things—on the backbone of art, culture, and community. A Tiger is powerful on its own, but together, the Streak is unstoppable. Our roar will be heard by all in the metaverse.
                    Join the Streak. </p>
            </div>
        )
    }

    const NewVisionSection = () => {
        return (
            <div style={{ height: '150vh' }}>
                <div className='darkGradient' />
                <motion.div style={{ opacity: scrollYProgress }} className='backgroundBlur' />

                <div className='poemText'>
                    <p>In a chaotic dystopia Jeremy Johnson awoke <br />Amongst the apes, the goblins, all the ugly folk</p>
                    <p>Lazy and tired, he worked nine to five <br />He hated his job, just worked to survive </p>
                    <p>He went to the bar to numb the pain <br />A broke sol bear offered to blow him for change </p>
                    <p>Jeremy cringed and turned his head to the left <br />Where he saw another tiger, their eyes met </p>
                    <p>His name was Mite Aswell, the two tigers acquainted <br />Over their frustrations, the tigers related </p>
                    <p>Seeking a change Mite and Jeremy escaped <br />In search of treasure and glory, the tigers had faith </p>
                    <p>They ran miles and miles, but did not know where <br />Yes they were tired, but they did not care </p>
                    <p>Far from home, they were ready to give up <br />But then they met Weezman, he gave them wisdom and luck  </p>
                    <p>With a taste of the Zoosh, the world filled with color <br />Jeremy’s name changed to Zoosh Johnson, sorry mother  </p>
                    <p>The tigers discovered they were the chosen ones <br />Never would they go back to the lives they came from  </p>
                    <p>On quest for the Zoosh, the Tigers embarked <br />They asked “who the fuck are we, Lewis and Clark?”  </p>
                    <p>Zoosh Johnson and Mite Aswell - it’s Zoosh that they seek <br />On their path they met Tigers, and born was the Streak  </p>
                </div>

                <div className='backgroundVision' />
            </div>
        )
    }

    return (
        <div>
            <Navbar navbarClasses='navbar navbar-expand-md navbar-dark bg-custom fixed-top rmBlur' />
            <NewVisionSection />
        </div>
    )
}

export default Vision