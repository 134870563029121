import React from 'react'
import './Home.scss'
import Accordion from 'react-bootstrap/Accordion'
import { Link } from "react-router-dom";
import { Controller, Scene } from 'react-scrollmagic';
import { NavLink } from "react-router-dom";

// Components
import Navbar from '../../Components/Navbar/Navbar'

// Assets
import MainVideo from '../../Components/MainVideo/MainVideo'
import story from '../../Assets/Images/story.png'
import feature1 from '../../Assets/Images/feature1.png'
import feature2 from '../../Assets/Images/feature2.png'
import feature3 from '../../Assets/Images/feature3.png'
import feature4 from '../../Assets/Images/feature4.png'
import feature5 from '../../Assets/Images/feature5.png'
import twitter from '../../Assets/Images/twitter.svg'
import linkedin from '../../Assets/Images/linkedin.svg'
import twitternft from '../../Assets/Images/twitternft.png'
import discordnft from '../../Assets/Images/discordnft.png'
import nft1 from '../../Assets/Images/nft1.png'
import nft2 from '../../Assets/Images/nft2.png'

// NFTs
import katana from '../../Assets/Images/katana.png'
// import gallery from '../../Assets/Images/gallerynft.png'
import KillBill from '../../Assets/Images/KillBill.png'

// Team
import nick from '../../Assets/Images/nick.jpg'
import ben from '../../Assets/Images/benny.jpg'
import caetano from '../../Assets/Images/caetano.jpg'
import juliana from '../../Assets/Images/juliana.jpg'
import gio from '../../Assets/Images/gio.jpg'
import gianluca from '../../Assets/Images/gianluca.jpg'

const AboutSection = () => {
    return (
        <div className='sectionAbout' id='about' name="about">
            <h1>Welcome to <br />the Streak</h1>
            <p>Tiger Streak is a collection of 10,000 utility-enabled Tiger NFTs—unique digital collectibles living on the Ethereum blockchain. Your Tiger is your key to the Zoosh Club and grants access to members-only benefits. It starts with access to our gated Zoosh Discord channels, Tiger Streak drops, functions, and The Zoosh: a tabula rasa Web3 platform. As the blueprint turns to bricks, new paths will reveal unlocking additional benefits for holders.</p>
        </div>
    )
}

const FeaturesSection = () => {
    return (
        <div className='sectionFeatures'>
            {/* <h1>Our Utility</h1> */}
            <div className='featureGrid'>
                <div className='featureContainer'>
                    <img src={feature1} alt="feature" className='featureImage' />
                    <p>10,000 unique Tiger PFPs</p>
                </div>
                <div className='featureContainer'>
                    <img src={feature2} alt="feature" className='featureImage' />
                    <p>Access to The Zoosh Club</p>
                </div>
                <div className='featureContainer'>
                    <img src={feature3} alt="feature" className='featureImage' />
                    <p>ERC-721A ETH tokens hosted on IPFS</p>
                </div>
                <div className='featureContainer'>
                    <img src={feature5} alt="feature" className='featureImage' />
                    <p>Commercial usage rights given to holders</p>
                </div>
                <div className='featureContainer'>
                    <img src={feature4} alt="feature" className='featureImage' />
                    <p>Utility NFT</p>
                </div>
            </div>
        </div>
    )
}

const TeamSection = () => {
    return (
        <div className='section'>
            <h1>The Team</h1>
            <p>The team behind the tigers. We’re not gifted, we’re just driven.</p>
            <div className='teamContainer'>
                <div className='teamCard'>
                    <div>
                        <img src={nick} alt="nick" className='teamImage' />
                        <div className='teamInfo'>
                            <p className='memberText'>Ex-software private equity. Philosopher.</p>
                        </div>
                    </div>
                    <div className='teamCardText'>
                        <div className='flexRow'>
                            <h2>Nick</h2>
                            <a href='https://twitter.com/NickKhalili' target="_blank"><img src={twitter} alt="" /></a>
                        </div>
                        <h3>ZOOSHTER</h3>
                    </div>
                </div>
                <div className='teamCard'>
                    <div>
                        <img src={ben} alt="ben" className='teamImage' />
                        <div className='teamInfo'>
                            <p className='memberText'>Went from buying houses to JPEGs.</p>
                        </div>
                    </div>
                    <div className='teamCardText'>
                        <div className='flexRow'>
                            <h2>Ben</h2>
                            <a href='https://twitter.com/BennyMelamed' target="_blank"><img src={twitter} alt="" /></a>
                        </div>
                        <h3>ZOOSHTER</h3>
                    </div>
                </div>
                <div className='teamCard'>
                    <div>
                        <img src={caetano} alt="caetano" className='teamImage' />
                        <div className='teamInfo'>
                            <p className='memberText'>15 years in 3D art. Award-winning digital Picasso. Basically some dude from Brazil.
                            </p>
                        </div>
                    </div>
                    <div className='teamCardText'>
                        <div className='flexRow'>
                            <h2>caetano</h2>
                            <a href='https://twitter.com/CaetanoSilva3D' target="_blank"><img src={twitter} alt="" /></a>
                        </div>
                        <h3>Lead Artist</h3>
                    </div>
                </div>
                <div className='teamCard'>
                    <div>
                        <img src={juliana} alt="juliana" className='teamImage' />
                        <div className='teamInfo'>
                            <p className='memberText'>Caetano’s sister. Tiger sous-chef & illustrator.</p>
                        </div>
                    </div>
                    <div className='teamCardText'>
                        <div className='flexRow'>
                            <h2>juliana</h2>
                            <a href='https://www.linkedin.com/in/julianadecampossilva/' target="_blank"><img src={linkedin} alt="" /></a>
                        </div>
                        <h3>Design</h3>
                    </div>
                </div>
                <div className='teamCard'>
                    <div>
                        <img src={gio} alt="gio" className='teamImage' />
                        <div className='teamInfo'>
                            <p className='memberText'>Computer Scientist. Smart contract scribe.</p>

                        </div>
                    </div>
                    <div className='teamCardText'>
                        <div className='flexRow'>
                            <h2>Gio</h2>
                            <a href='https://twitter.com/giozaarour' target="_blank"><img src={twitter} alt="" /></a>
                        </div>
                        <h3>Developer</h3>
                    </div>
                </div>
                <div className='teamCard'>
                    <div>
                        <img src={gianluca} alt="gianluca" className='teamImage' />
                        <div className='teamInfo'>
                            <p className='memberText'>Tiger Shepherd. NFT art collector. Enjoys scrolling through instagram on mothers day. </p>
                        </div>
                    </div>
                    <div className='teamCardText'>
                        <div className='flexRow'>
                            <h2>Gianluca</h2>
                            <a href='https://twitter.com/da_manicho' target="_blank"><img src={twitter} alt="" /></a>
                        </div>
                        <h3>Community Manager</h3>
                    </div>
                </div>
            </div>
        </div>
    )
}

const FollowSection = () => {
    return (
        <div className='section' id='zoosh'>
            <h1>FOLLOW THE ZOOSH</h1>
            <div className='followContainer'>
                <NavLink className='followCard' to='/blueprint'>
                    <div><img src={nft1} alt="story" className='followImage' /></div>
                    <div className='followText'>
                        <h2>Blueprint</h2>
                        <h3>The Good Stuff</h3>
                    </div>
                </NavLink>
                <NavLink className='followCard' to='/vision'>
                    <div><img src={nft2} alt="story" className='followImage' /></div>
                    <div className='followText'>
                        <h2>Jungle</h2>
                        <h3>The Tale of the Tiger</h3>
                    </div>
                </NavLink>
                <a className='followCard' href="https://twitter.com/tigerstreaknft" target="_blank">
                    <div><img src={discordnft} alt="story" className='followImage' /></div>
                    <div className='followText'>
                        <h2>Twitter</h2>
                        <h3>Stay Updated</h3>
                    </div>
                </a>
                <a className='followCard' href='https://discord.gg/H3nhxuBD' target="_blank">
                    <div><img src={twitternft} alt="story" className='followImage' /></div>
                    <div className='followText'>
                        <h2>Discord</h2>
                        <h3>Tiger Hub</h3>
                    </div>
                </a>
            </div>
        </div>
    )
}

const JoinStreak = () => {
    return (
        <div className='section'>
            <h1>JOIN THE STREAK</h1>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Facere incidunt eum dolor.</p>
            <Link to='/buy' className='btn btnMint'>Mint Information</Link>
        </div>
    )
}

const Faq = () => {
    return (
        <div className='section faqStyle'>
            <h1>frequently asked questions</h1>
            <Accordion className='accordionContainer'>
                <Accordion.Item eventKey="0">
                    <Accordion.Header className='faqHeader'>When is the mint?</Accordion.Header>
                    <Accordion.Body>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Ut, voluptas! Iure veniam facere voluptatem, aut sit numquam reprehenderit ipsam magni?
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header className='faqHeader'>When is the mint?</Accordion.Header>
                    <Accordion.Body>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Ut, voluptas! Iure veniam facere voluptatem, aut sit numquam reprehenderit ipsam magni?
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                    <Accordion.Header className='faqHeader'>When is the mint?</Accordion.Header>
                    <Accordion.Body>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Ut, voluptas! Iure veniam facere voluptatem, aut sit numquam reprehenderit ipsam magni?
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                    <Accordion.Header className='faqHeader'>When is the mint?</Accordion.Header>
                    <Accordion.Body>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Ut, voluptas! Iure veniam facere voluptatem, aut sit numquam reprehenderit ipsam magni?
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                    <Accordion.Header className='faqHeader'>When is the mint?</Accordion.Header>
                    <Accordion.Body>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Ut, voluptas! Iure veniam facere voluptatem, aut sit numquam reprehenderit ipsam magni?
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </div>
    )
}

const MainNFT = () => {
    return (
        <div>
            <img src={katana} alt="KB_Katana" className='homeNFTMain' />
        </div>
    )
}

/* const ScrollMagic = () => {
    return (
        <div>
            <div className="sectionScroll" />
            <div id="trigger" />
            <Controller>
                <Scene duration={200} classToggle="zap" triggerElement="#trigger" indicators={true}>
                    <div><img src={katana} alt="KB_Katana" className='homeNFT' /></div>
                </Scene>
                <Scene classToggle={['.test', 'yellow']} reverse={false} indicators={true}>
                    <div>Toggle other class</div>
                </Scene>
            </Controller>
            <div className="sectionScroll" />
        </div>
    )
} */

function Home() {
    return (
        <div>
            <Navbar navbarClasses='navbar navbar-expand-md navbar-dark bg-custom fixed-top' />
            <MainVideo />
            <div className='homeContainer'>
                <Controller>
                    <div className='hideMobile'>
                        <div id="trigger" />
                        <Scene duration={"300%"} classToggle="showNFT" triggerElement="#trigger" triggerHook='center'>
                            <img src={katana} alt="KB_Katana" className='homeNFT' />
                        </Scene>
                    </div>
                    <AboutSection />
                    <FeaturesSection />
                    <TeamSection />
                    <div className='hideMobile'>
                        <div id="trigger2" />
                        <Scene duration={"120%"} classToggle="showNFT" triggerElement="#trigger2" triggerHook='center'>
                            <img src={KillBill} alt="KB_Katana" className='homeNFT' />
                        </Scene>
                    </div>
                    <FollowSection />
                </Controller>
                <MainNFT className="showMobile" />
            </div >
        </div >
    )
}

export default Home