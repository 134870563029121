import React from 'react'
import '../Blueprint.scss'
import { Link } from 'react-router-dom'
import './Detailed.scss'

// Components
import Navbar from '../../../Components/Navbar/Navbar'

//Images
import frameColor from '../../../Assets/Images/framecolor.png'

function Art() {

    return (
        <div className='Bluebackground'>
            <Navbar navbarClasses='navbar navbar-expand-md navbar-dark bg-custom fixed-top' />
            <div className='blueContainer'>
                <div className='detailedGrid'>
                    <div>
                        <Link to='/blueprint' className='backBtn'> &lt; Return </Link>
                        <h1>Art</h1>
                        <p>Each Tiger is unique and algorithmically generated from over 250 possible traits, including expression, clothing, accessories and more.  Each trait contains its own unique genome that combines to decide the resulting Tiger’s bloodline. There are 9 bloodlines, each varying in rarity, origin, and meaning. </p>
                        <p>Some tigers are rarer than others, but all share the same benefits.</p>
                        <p>The NFTs are rendered in 4k resolution. The gallery is coming soon—so you can properly simp.</p>
                        <p>Ownership and commercial usage rights are given to the holder over their NFT. </p>

                        <p><b>We are delivering ways to give our tigers access across metaverses and realities.</b> This will happen in ways that unlock new forms of access and interaction for our holders, allowing 3D tiger avatars to be used across platforms and to serve as your digital identity.</p>
                    </div>
                    <div className='detailedimgContainer'>
                        <img src={frameColor} alt="vision" className='detailedImage' />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Art