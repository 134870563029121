import React, { useState } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

import Home from './Pages/Home/Home'
import Gallery from './Pages/Gallery/Gallery'
import Vision from './Pages/Vision/Vision'
import Buy from './Pages/Buy/Buy'
import Blueprint from './Pages/Blueprint/Blueprint'
import Crux from './Pages/Crux/Crux'
import 'bootstrap/dist/css/bootstrap.min.css';

import DetailVision from './Pages/Blueprint/Detailed/Vision'
import DetailArt from './Pages/Blueprint/Detailed/Art'
import DetailSoftwarePlatform from './Pages/Blueprint/Detailed/SoftwarePlatform'
import DetailZooshClub from './Pages/Blueprint/Detailed/ZooshClub'
import DetailBeyond from './Pages/Blueprint/Detailed/Beyond'
import DetailRealWorld from './Pages/Blueprint/Detailed/RealWorld'

function App() {

  /* const [isReady, setIsReady] = React.useState(false);

  if (!isReady) {
    return <div>loading...</div>
  } */

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/jungle" element={<Vision />} />
        <Route path="/mint" element={<Buy />} />
        <Route path="/blueprint" element={<Blueprint />} />

        <Route path="/crux" element={<Crux />} />

        <Route path="/blueprint/vision" element={<DetailVision />} />
        <Route path="/blueprint/art" element={<DetailArt />} />
        <Route path="/blueprint/software-platform" element={<DetailSoftwarePlatform />} />
        <Route path="/blueprint/zoosh-club" element={<DetailZooshClub />} />
        <Route path="/blueprint/beyond" element={<DetailBeyond />} />
        <Route path="/blueprint/real-world" element={<DetailRealWorld />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
