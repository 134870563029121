import React from 'react'
import './Blueprint.scss'
import { Link, useNavigate } from 'react-router-dom'
import { motion } from "framer-motion"

// Components
import Navbar from '../../Components/Navbar/Navbar'

// Assets
// import storynft from '../../Assets/Images/storynft.png'
// import ethtrace from '../../Assets/ethtrace.png'

function Blueprint() {
    const navigate = useNavigate();

    const navigateVision = () => {
        navigate('/blueprint/vision');
    };
    const navigateArt = () => {
        navigate('/blueprint/art');
    };
    const navigateSoftwarePlatform = () => {
        navigate('/blueprint/software-platform');
    };
    const navigateZooshClub = () => {
        navigate('/blueprint/zoosh-club');
    };
    const navigateBeyond = () => {
        navigate('/blueprint/beyond');
    };
    const navigateRealWorld = () => {
        navigate('/blueprint/real-world');
    };

    const BluePrintGrid = () => {
        return (
            <div className='bluePrintGrid'>
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 1.2 }}
                    className='blueprintContainer'
                    onClick={navigateVision}
                >
                    <h2>Vision</h2>
                </motion.div>
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 1.9 }}
                    className='blueprintContainer'
                    onClick={navigateArt}
                >
                    <h2>Art</h2>
                </motion.div>
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 2.25 }}
                    className='blueprintContainer large'
                    onClick={navigateSoftwarePlatform}
                >
                    <h2>Software Platform</h2>
                </motion.div>
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 2.60 }}
                    className='blueprintContainer large'
                    onClick={navigateZooshClub}
                >
                    <h2>Zoosh Club</h2>
                </motion.div>
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 2.95 }}
                    className='blueprintContainer'
                    onClick={navigateBeyond}
                >
                    <h2>Beyond</h2>
                </motion.div>
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 1.55 }}
                    className='blueprintContainer medium'
                    onClick={navigateRealWorld}
                >
                    <h2>Real World</h2>
                </motion.div>
            </div>
        )
    }

    return (
        <div className='Bluebackground'>
            <Navbar navbarClasses='navbar navbar-expand-md navbar-dark bg-custom fixed-top' />
            <div className='blueContainer'>
                <h1 className='textBorder'>The Blueprint</h1>
                <BluePrintGrid />
            </div>
        </div>
    )
}

export default Blueprint