import React from 'react'
import '../Blueprint.scss'
import { Link } from 'react-router-dom'
import './Detailed.scss'

// Components
import Navbar from '../../../Components/Navbar/Navbar'

//Images
import beyondColor from '../../../Assets/Images/beyondcolor.png'

function RealWorld() {

    return (
        <div className='Bluebackground'>
            <Navbar navbarClasses='navbar navbar-expand-md navbar-dark bg-custom fixed-top' />
            <div className='blueContainer'>
                <div className='detailedGrid'>
                    <div>
                        <Link to='/blueprint' className='backBtn'> &lt; Return </Link>
                        <h1>Real World</h1>
                        <p><b>IRL Events.</b> Live events and community meet-ups allow us to bring the Streak together beyond the metaverse. We believe that the most special moments are the ones that bring us together eye to eye and allow us to connect, build friendships, and be human. Also… who doesn’t love a function?</p>
                        <p><b>Merch.</b> Nuff said.</p>
                    </div>
                    <div className='detailedimgContainer'>
                        <img src={beyondColor} alt="vision" className='detailedImage' />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RealWorld