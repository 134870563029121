import React from 'react'
import '../Blueprint.scss'
import { Link } from 'react-router-dom'
import './Detailed.scss'

// Components
import Navbar from '../../../Components/Navbar/Navbar'

//Images
import zooshColor from '../../../Assets/Images/zooshcolor.png'

function ZooshClub() {

    return (
        <div className='Bluebackground'>
            <Navbar navbarClasses='navbar navbar-expand-md navbar-dark bg-custom fixed-top' />
            <div className='blueContainer'>
                <div className='detailedGrid'>
                    <div>
                        <Link to='/blueprint' className='backBtn'> &lt; Return </Link>
                        <h1>Zoosh Club</h1>
                        <p>It starts with access to the members-only Discord where members can collaborate and grow the Zoosh Club together. The Zoosh Club was created around a story and a philosophy, and its purpose is for our members to decrypt, unlock, and decide its path. </p>
                        <p><b>We are experimenting with DAOs for the Zoosh Club’s optimal structure and governance.</b> A DAO would provide holders with direct jurisdiction over community decisions and the direction of the Zoosh Club.</p>
                    </div>
                    <div className='detailedimgContainer'>
                        <img src={zooshColor} alt="vision" className='detailedImage' />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ZooshClub