import React from 'react'
import './Gallery.scss'

// Components
import Navbar from '../../Components/Navbar/Navbar'

// Assets
import gallerynft from '../../Assets/Images/Gallery_Tiger.png'
import galleryGrid from '../../Assets/Images/gallery.jpeg'

function Gallery() {

    const MainNFT = () => {
        return (
            <div>
                <img src={gallerynft} alt="KB_Katana" className='otherNFT' />
            </div>
        )
    }

    const ComingSoon = () => {
        return (
            <div className='gallerySection'>
                <h2>Coming Soon</h2>
                <h1>GALLERY</h1>
                {/* <img src={galleryGrid} alt="gallery" className='galleryGrid' /> */}
            </div>
        )
    }

    return (
        <div>
            <Navbar navbarClasses='navbar navbar-expand-md navbar-dark bg-custom fixed-top' />
            <div className='homeContainer'>
                <ComingSoon />
                <MainNFT />
            </div>
        </div>
    )
}

export default Gallery