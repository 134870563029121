import React from 'react'
import '../Blueprint.scss'
import { Link } from 'react-router-dom'
import './Detailed.scss'

// Components
import Navbar from '../../../Components/Navbar/Navbar'

//Images
import ethColor from '../../../Assets/Images/ethcolor.png'

function Vision() {

    return (
        <div className='Bluebackground'>
            <Navbar navbarClasses='navbar navbar-expand-md navbar-dark bg-custom fixed-top' />
            <div className='blueContainer'>
                <div className='detailedGrid'>
                    <div>
                        <Link to='/blueprint' className='backBtn'> &lt; Return </Link>
                        <h1>The Vision</h1>
                        <p>We are in a unique window of time and space where the digital and physical overlap, allowing art, culture, and community to mesh with real utility. We’re here to create value, tell a story, and have a good time. We will break barriers, set new boundaries, and probably piss some people off on the way. </p>
                        <p>We are fearless—the only thing we fear is fear itself.</p>

                        <p><b>Family.</b> We were raised on strong family values—trust, respect, and loyalty. That’s the way our team operates, and that’s what we bring to the Streak. The Streak is no less than a family—a network growing and building together. The most valuable asset as we build is the input and involvement of our community.</p>
                        <p><b>Trailblazers.</b> There’s no fun in taking the beaten path. The treasure lies in a place unfamiliar, where one has not yet stepped. Nothing good ever came out of following others. We will pave our own way. Don’t be a sheep, be a Tiger.</p>
                        <p><b>Excellence.</b> Zoosh is about eliminating fear and allowing your true self to shine through, so you can act with your heart and give your gift to the world. From hours of careful thought and execution, greatness is born. You gotta want it.</p>
                        <p><b>Transparency.</b> We’re not leaving anything out. You know who we are, and we aren’t going anywhere. We're on the same journey with you, building and improving alongside. </p>
                        <p><b>Positivity.</b> We believe in a decentralized future and are here to create that future and have fun with it. Positivity and resilience will lead the way. Let’s enjoy the ride.</p>
                    </div>
                    <div className='detailedimgContainer'>
                        <img src={ethColor} alt="vision" className='detailedImage2' />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Vision