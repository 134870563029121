import React from 'react'
import './Buy.scss'

// Components
import Navbar from '../../Components/Navbar/Navbar'

// Assets
// import buynft from '../../Assets/Images/buynft.png'
import buyVideo from '../../Assets/Videos/zooshNew.mp4'
import timeline from '../../Assets/Images/timeline.svg'

function Buy() {

    const MainNFT = () => {
        return (
            <div>
                <video
                    className='buyNFT'
                    autoPlay
                    loop
                    muted
                    playsInline
                    src={buyVideo}
                    type="video/mp4">
                </video>
            </div>
        )
    }

    const ComingSoon = () => {
        return (
            <div className='sectionBuy'>
                <img src={timeline} alt="timline" className='timelineImage' />
                <h1>JOIN THE STREAK</h1>
                <p>Tiger Streak is a collection of 10,000 algorithmically generated digital collectibles that double as membership cards for the Zoosh Club. No Tiger is the same—each has a unique set of traits from over 300 possibilities, including expression, headwear, clothing, and more. All Tigers provide members-only benefits, but some are rarer than others. Your Tiger’s unique combination of traits and DNA serves as an expression of your individuality in this cool new place we call the metaverse. </p>
                <div className='twoCol'>
                    <h2>Select Amount</h2>
                    <div>
                        <button className='intervalButton'>-</button>
                        <input type="number" min='0' max='2' placeholder='1' className='mintForm' disabled />
                        <button className='intervalButton'>+</button>
                    </div>
                </div>
                <div className='twoCol'>
                    <h2>Price</h2>
                    <h2>TBD</h2>
                </div>
                <div className='text-center mt-2'>
                    <a href="" className='btn btnMint mb-4'>Mint</a>
                    <p>Total Supply: 10,000</p>
                    <p>Max: 2 per wallet</p>
                </div>
            </div>
        )
    }

    return (
        <div>
            <Navbar navbarClasses='navbar navbar-expand-md navbar-dark bg-custom fixed-top' />
            <div className='homeContainer'>
                <ComingSoon />
                <MainNFT />
            </div>
        </div>
    )
}

export default Buy