import React from 'react'
import '../Blueprint.scss'
import { Link } from 'react-router-dom'
import './Detailed.scss'

// Components
import Navbar from '../../../Components/Navbar/Navbar'

//Images
import shipColor from '../../../Assets/Images/shipcolor.png'

function Beyond() {

    return (
        <div className='Bluebackground'>
            <Navbar navbarClasses='navbar navbar-expand-md navbar-dark bg-custom fixed-top' />
            <div className='blueContainer'>
                <div className='detailedGrid'>
                    <div>
                        <Link to='/blueprint' className='backBtn'> &lt; Return </Link>
                        <h1>Beyond</h1>
                        <p><b>Partnerships.</b> We’re in talks with players in tech, music, fashion and the Web3 space at large that share our vision and values. Collaborations provide us with new networks, access to breakthrough technologies, and allow the Tiger Streak community to make ourselves heard in all walks of life.</p>
                        <p><b>Next.</b> The decentralized space is evolving rapidly into the future. We will grow with it, adapt with it, and define it. Along the journey, new paths will reveal, and together we will embark to discover new treasures.</p>
                    </div>
                    <div className='detailedimgContainer'>
                        <img src={shipColor} alt="vision" className='detailedImage' />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Beyond