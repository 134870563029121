import React from 'react'
import '../Blueprint.scss'
import { Link } from 'react-router-dom'
import './Detailed.scss'

// Components
import Navbar from '../../../Components/Navbar/Navbar'

//Images
import wandColor from '../../../Assets/Images/wandcolor.png'

function SoftwarePlatform() {

    return (
        <div className='Bluebackground'>
            <Navbar navbarClasses='navbar navbar-expand-md navbar-dark bg-custom fixed-top' />
            <div className='blueContainer'>
                <div className='detailedGrid'>
                    <div>
                        <Link to='/blueprint' className='backBtn'> &lt; Return </Link>
                        <h1>Software Platform</h1>
                        <p><b>We’re here to build the bridge between Web2 and Web3.</b></p>
                        <p><b>Leveraging the power of AI and data-based personalization, we are building a platform that optimizes how we interact socially and professionally in the NFT space.</b> Its purpose is to bring people together and enhance the powers of community, connecting friends in a Web3-optimized way never before seen. We can’t say much yet, just know something is brewing.</p>
                        <p>We’re building Zoosh to amplify the structure and benefits of community membership through NFTs, bring creators and their respective audiences together using algorithms, and create a more inclusive and further reaching Web3 experience that can be adopted at scale.</p>
                        <p><b>Tiger Streak holders will be the first to access it and will forever receive exclusive features and benefits. </b></p>
                    </div>
                    <div className='detailedimgContainer'>
                        <img src={wandColor} alt="vision" className='detailedImage' />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SoftwarePlatform