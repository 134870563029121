import React from 'react'
import './Navbar.scss';
import { NavLink } from "react-router-dom";
import logo from '../../Assets/Images/coloredlogo.png'
import twitter from '../../Assets/Images/twitter.svg'
import discord from '../../Assets/Images/discord.svg'

const Navbar = ({ navbarClasses }) => {
    return (
        <div id='navbar'>
            <nav className={navbarClasses}>
                <div className="container-fluid navbarWidth">
                    <NavLink to="/"><img className="navlogo" src={logo} alt="logo" /></NavLink>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse"
                        aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse useActive" id="navbarCollapse">
                        <ul className="navbar-nav ms-auto pe-3 mt-1 mb-md-0" >
                            <li className="nav-item ms-lg-3 ms-md-2">
                                <NavLink className="nav-link" to="/crux">crux</NavLink>
                            </li>
                            <li className="nav-item ms-lg-3 ms-md-2">
                                <NavLink className="nav-link" to="/mint">mint</NavLink>
                            </li>
                            <li className="nav-item ms-lg-3 ms-md-2">
                                <NavLink className="nav-link" to="/blueprint">blueprint</NavLink>
                            </li>
                            <li className="nav-item ms-lg-3 ms-md-2">
                                <NavLink className="nav-link" to="/jungle">jungle</NavLink>
                            </li>
                            <li className="nav-item ms-lg-3 ms-md-2">
                                <NavLink className="nav-link" to="/gallery">gallery</NavLink>
                            </li>
                        </ul>
                        <form className="d-flex socialIcons">
                            <a href="https://twitter.com/tigerstreaknft" target="_blank"><img src={twitter} alt="twitter" className='navIcon' /></a>
                            <a href="https://discord.gg/H3nhxuBD" target="_blank"><img src={discord} alt="discord" className='navIcon' /></a>
                        </form>
                    </div>
                </div>
            </nav >
        </div >
    )
}

export default Navbar
